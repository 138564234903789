import {createContext, useContext} from 'react';
import {useSocketEmit} from '../hooks/useSocketEmit';
import {ReactElementPropsType} from '../types';
import GetResultRequest from "../models/GetResultRequest";
import GetResultResponse from "../models/GetResultResponse";
import SaveResultRequest from "../models/SaveResultRequest";

interface Context {
    setRooms?: (data: SaveResultRequest) => Promise<Boolean>,
    addEdit?: (data: GetResultRequest) => Promise<GetResultResponse>,
}

const initContext: Context = {}

export const RoomsContext = createContext(initContext);

export const useRooms = () => useContext(RoomsContext)

export const ResultProvider = (props: ReactElementPropsType) => {
    const {getResult, saveResult} = useSocketEmit()
    const addEdit = async (data: GetResultRequest) => {
        return new Promise<GetResultResponse>(async (resolve, reject) => {
            try {

                const result = await getResult(data);
                resolve(result);
            } catch(e) {
                reject(e)
            }
        })
    }

    const setRooms = async (data: SaveResultRequest) => {
        return new Promise<Boolean>(async (resolve, reject) => {
            try {

                const result = await saveResult(data);
                resolve(result);
            } catch(e) {
                reject(e)
            }
        })
    }

    return <RoomsContext.Provider value={{setRooms, addEdit}} >{props.children}</RoomsContext.Provider>
};
