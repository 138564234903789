import {useContext} from 'react';
import {SocketContext} from "../contexts/SocketProvider";
import {config} from "../config";
import GetResultRequest from "../models/GetResultRequest";
import GetResultResponse from "../models/GetResultResponse";
import SaveResultRequest from "../models/SaveResultRequest";

type ResponseTemplate = {
    result: {
        success: boolean,
        data?: object | [object],
        error: object
    }
}

export type ErrorType = {
    code: number | null,
    message: string,
    status: number,
}

export const useSocketEmit = () => {
    const {socket} = useContext(SocketContext);
    const getResult = async (options: GetResultRequest) => {
        console.log('calling',config.actions.GET_RESULT, options)
        return addEditRoom(options);
    }
    const addEditRoom = async (options: GetResultRequest) => {
        return new Promise<GetResultResponse>((resolve, reject)  => {
            socket?.emit(config.actions.GET_RESULT, options, (response: ResponseTemplate)  => {
                console.log(config.actions.GET_RESULT, response)
                if (response.result?.success) {
                    resolve(response.result.data as GetResultResponse)
                } else reject(response.result.error as ErrorType )
            })
        })
    }

    const saveResult = async (options: SaveResultRequest) => {
        return new Promise<Boolean>((resolve, reject)  => {
            console.log('calling',config.actions.SAVE_RESULT, options)
            socket?.emit(config.actions.SAVE_RESULT, options, (response: ResponseTemplate)  => {
                console.log(config.actions.SAVE_RESULT, response)
                if (response.result?.success) {
                    resolve(true)
                } else reject(response.result.error as ErrorType )
            })
        })
    }


    return {
        socket,
        getResult,
        saveResult
    }
}
