import Question from "./Question";

export default class QuestionList {
  data: Question[];

  constructor(
    data: Question[],
  ) {
    this.data = data;
  }

  getQuestionByTopic(topic: QuestionTopic) {
    return this.data.filter(question => question.topic === topic);
  }

  countQuestionByTopic(topic: QuestionTopic) {
    return this.getQuestionByTopic(topic).length;
  }

  countAllQuestions() {
    return this.data.length;
  }
}

export enum QuestionTopic {
  team = 'team',
  marketing = 'marketing',
  finance = 'finance',
  legal = 'legal',
  product = 'product',
  validation = 'validation',
  esg = 'esg',

}

