import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useRooms} from '../../../contexts/ResultProvider';
import {FormProvider} from '../../../hooks/useForm';
import {theme} from '../../../theme';
import Button from '../../ui/Button/Button';
import Input from '../../ui/Input';
import Text from '../../ui/Text';
import {aiParamValidator, descriptionValidator, maxQuestionValidator, modelValidator} from '../../../validators/Result';
import GetResultRequest from "../../../models/GetResultRequest";
import {QuestionTopic} from "../../../models/QuestionList";

const ResultForm = (props: any) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {addEdit} = useRooms()
  const showPrompt = new URLSearchParams(window.location.search).get('prompt') === 'yes';

  const { register, handleSubmit,  formState: {errors}} = useForm({
    mode:'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      name: props.item?.name || '',
      threshold: props.item?.threshold || 7,
      topP: props.item?.topP || 0.5,
      temperature: props.item?.temperature || 0,
      frequencyPenalty: props.item?.frequencyPenalty || 0,
      presencePenalty: props.item?.presencePenalty || 0,
      model: props.item?.model || "gpt-4-1106-preview",
      descriptionTeam: props.item?.descriptionTeam || '',
      descriptionProduct: props.item?.descriptionProduct || '',
      descriptionMarketing: props.item?.descriptionMarketing || '',
      descriptionFinance: props.item?.descriptionFinance || '',
      descriptionLegal: props.item?.descriptionLegal || '',
      descriptionValidation: props.item?.descriptionValidation || '',
      descriptionEsg: props.item?.descriptionEsg || '',
      maxQuestionTeam: props.item?.maxQuestionTeam || 5,
      maxQuestionProduct: props.item?.maxQuestionProduct || 5,
      maxQuestionMarketing: props.item?.maxQuestionMarketing || 5,
      maxQuestionFinance: props.item?.maxQuestionFinance || 5,
      maxQuestionLegal: props.item?.maxQuestionLegal || 5,
      maxQuestionValidation: props.item?.maxQuestionValidation || 5,
      maxQuestionEsg: props.item?.maxQuestionEsg || 5,
      prompt: "Tu sei un esaminatore di aziende virtuale. Rispondi alle domande con 'SI'  se pensi sia si la risposta, 'NO' se pensi che sia no la risposta, ed un numero in una scala da 1 a 10, che esprime la tua sicurezza nella risposta\n" +
          "La risposta per ogni domanda deve essere nel seguente formato: <numero domanda>.<2 caratteri>,<valore sicurezza>\n" +
          "Dai le risposte basandoti sulla seguente descrizione aziendale:"
    }
  });


  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const getResultRequest: GetResultRequest = new GetResultRequest(
        data.threshold,
        [
          {
            topic: QuestionTopic.team,
            maxQuestion: data.maxQuestionTeam,
            description: data.descriptionTeam
          },
          {
            topic: QuestionTopic.product,
            maxQuestion: data.maxQuestionProduct,
            description: data.descriptionProduct
          },
          {
            topic: QuestionTopic.marketing,
            maxQuestion: data.maxQuestionMarketing,
            description: data.descriptionMarketing
          },
          {
            topic: QuestionTopic.finance,
            maxQuestion: data.maxQuestionFinance,
            description: data.descriptionFinance
          },
          {
            topic: QuestionTopic.legal,
            maxQuestion: data.maxQuestionLegal,
            description: data.descriptionLegal
          },
          {
            topic: QuestionTopic.validation,
            maxQuestion: data.maxQuestionValidation,
            description: data.descriptionValidation
          },
          {
            topic: QuestionTopic.esg,
            maxQuestion: data.maxQuestionEsg,
            description: data.descriptionEsg
          }
        ],
        {
          topP: parseFloat(data.topP),
          temperature: parseFloat(data.temperature),
          frequencyPenalty: parseFloat(data.frequencyPenalty),
          presencePenalty: parseFloat(data.presencePenalty),
          model: data.model,
          prompt: data.prompt,
        }
      );
      console.log(getResultRequest)
      const result = await addEdit!(getResultRequest);
      setLoading(false);
      props.callback(result, getResultRequest)

      // props.close()
    }catch(e: any){
      setLoading(false);
      console.log(e)
      if(e.status === 400) {
        alert(e.message)
      } else {
        alert(e.message)
      }

    }

  };

  return (
      <div id={'roomFormRoot'}>
        <FormProvider >
          <form onSubmit={handleSubmit(onSubmit)}>
            {showPrompt &&<><hr/>
            <textarea  {...register('prompt', descriptionValidator)} placeholder="Prompt"
                       style={styles.textArea}/>
            </>}
            <hr/>
            <Text style={styles.title2} variant={'h3'}>Generale</Text>
            <div style={styles.formContainer}>
              <div style={styles.inputContainer}>
                <Text style={styles.label} variant={'h5'}>Threshold (1-10)</Text>
                <Input min={1} max={10} style={errors?.threshold ? styles.erroredInput : {}}
                       type={'number'} {...register('threshold', aiParamValidator)} />
                {errors?.threshold &&
                    <Text style={styles.error} variant={'p1'}>{t(errors.threshold.message as string) as string}</Text>}
              </div>
            </div>
            <hr/>
            <Text style={styles.title2} variant={'h3'}>Sezione AI</Text>
            <div style={styles.formContainer}>
              <div style={styles.inputContainer}>
                <Text style={styles.label} variant={'h5'}>Model</Text>
                <select style={styles.select} {...register('model', modelValidator)}>
                  <option defaultValue={'gpt-4-1106-preview'} value={'gpt-4-1106-preview'}>gpt-4-1106-preview</option>
                  <option value={'gpt-4'}>gpt-4</option>
                  <option value={'gpt-3.5-turbo'}>gpt-3.5-turbo</option>
                </select>
              </div>
              <div style={styles.inputContainer}>
                <Text style={styles.label} variant={'h5'}>Top P (0.0-1.0)</Text>
                <Input min={0} max={1} step={0.1} style={errors?.topP ? styles.erroredInput : {}}
                       type={'number'} {...register('topP', aiParamValidator)} />
                {errors?.topP &&
                    <Text style={styles.error} variant={'p1'}>{t(errors.topP.message as string) as string}</Text>}
              </div>
              <div style={styles.inputContainer}>
                <Text style={styles.label} variant={'h5'}>Temperature (0.0-2.0)</Text>
                <Input min={0} max={2} step={0.1} style={errors?.temperature ? styles.erroredInput : {}}
                       type={'number'} {...register('temperature', aiParamValidator)} />
                {errors?.temperature && <Text style={styles.error}
                                              variant={'p1'}>{t(errors.temperature.message as string) as string}</Text>}
              </div>
              <div style={styles.inputContainer}>
                <Text style={styles.label} variant={'h5'}>Frequency Penalty (0.0-2.0)</Text>
                <Input min={0} max={2} step={0.1} style={errors?.frequencyPenalty ? styles.erroredInput : {}}
                       type={'number'} {...register('frequencyPenalty', aiParamValidator)} />
                {errors?.frequencyPenalty && <Text style={styles.error}
                                                   variant={'p1'}>{t(errors.frequencyPenalty.message as string) as string}</Text>}
              </div>
              <div style={styles.inputContainer}>
                <Text style={styles.label} variant={'h5'}>Presence Penalty (0.0-2.0)</Text>
                <Input min={0} max={2} step={0.1} style={errors?.presencePenalty ? styles.erroredInput : {}}
                       type={'number'} {...register('presencePenalty', aiParamValidator)} />
                {errors?.presencePenalty && <Text style={styles.error}
                                                  variant={'p1'}>{t(errors.presencePenalty.message as string) as string}</Text>}
              </div>
            </div>

            <hr></hr>
            <div style={styles.inputContainer}>
              <Text style={styles.title} variant={'h3'}>Sezione Team</Text>
              <Text style={styles.label} variant={'h5'}>Descrizione</Text>
              <textarea  {...register('descriptionTeam', descriptionValidator)} placeholder="Descrizione"
                         style={styles.textArea}/>
              {errors?.descriptionTeam && <Text style={styles.error}
                                                variant={'p1'}>{t(errors.descriptionTeam.message as string) as string}</Text>}
              <Text style={styles.label} variant={'h5'}>N. Domande</Text>
              <Input type={'number'} {...register('maxQuestionTeam', maxQuestionValidator)} placeholder="N. Domande"
                     style={errors?.maxQuestionTeam ? styles.erroredInput : {}}/>
              {errors?.maxQuestionTeam && <Text style={styles.error}
                                                variant={'p1'}>{t(errors.maxQuestionTeam.message as string) as string}</Text>}
            </div>
            <hr></hr>
            <div style={styles.inputContainer}>
              <Text style={styles.title} variant={'h3'}>Sezione Product</Text>
              <Text style={styles.label} variant={'h5'}>Descrizione</Text>
              <textarea  {...register('descriptionProduct', descriptionValidator)} placeholder="Descrizione"
                         style={styles.textArea}/>
              {errors?.descriptionProduct && <Text style={styles.error}
                                                   variant={'p1'}>{t(errors.descriptionProduct.message as string) as string}</Text>}
              <Text style={styles.label} variant={'h5'}>N. Domande</Text>
              <Input type={'number'} {...register('maxQuestionProduct', maxQuestionValidator)} placeholder="N. Domande"
                     style={errors?.maxQuestionProduct ? styles.erroredInput : {}}/>
              {errors?.maxQuestionProduct && <Text style={styles.error}
                                                   variant={'p1'}>{t(errors.maxQuestionProduct.message as string) as string}</Text>}
            </div>

            <hr></hr>
            <div style={styles.inputContainer}>
              <Text style={styles.title} variant={'h3'}>Sezione Marketing</Text>
              <Text style={styles.label} variant={'h5'}>Descrizione</Text>
              <textarea  {...register('descriptionMarketing', descriptionValidator)} placeholder="Descrizione"
                         style={styles.textArea}/>
              {errors?.descriptionMarketing && <Text style={styles.error}
                                                     variant={'p1'}>{t(errors.descriptionMarketing.message as string) as string}</Text>}
              <Text style={styles.label} variant={'h5'}>N. Domande</Text>
              <Input type={'number'} {...register('maxQuestionMarketing', maxQuestionValidator)}
                     placeholder="N. Domande" style={errors?.maxQuestionMarketing ? styles.erroredInput : {}}/>
              {errors?.maxQuestionMarketing && <Text style={styles.error}
                                                     variant={'p1'}>{t(errors.maxQuestionMarketing.message as string) as string}</Text>}
            </div>

            <hr></hr>
            <div style={styles.inputContainer}>
              <Text style={styles.title} variant={'h3'}>Sezione Finance</Text>
              <Text style={styles.label} variant={'h5'}>Descrizione</Text>
              <textarea  {...register('descriptionFinance', descriptionValidator)} placeholder="Descrizione"
                         style={styles.textArea}/>
              {errors?.descriptionFinance && <Text style={styles.error}
                                                   variant={'p1'}>{t(errors.descriptionFinance.message as string) as string}</Text>}
              <Text style={styles.label} variant={'h5'}>N. Domande</Text>
              <Input type={'number'} {...register('maxQuestionFinance', maxQuestionValidator)} placeholder="N. Domande"
                     style={errors?.maxQuestionFinance ? styles.erroredInput : {}}/>
              {errors?.maxQuestionFinance && <Text style={styles.error}
                                                   variant={'p1'}>{t(errors.maxQuestionFinance.message as string) as string}</Text>}
            </div>

            <hr></hr>
            <div style={styles.inputContainer}>
              <Text style={styles.title} variant={'h3'}>Sezione Legal</Text>
              <Text style={styles.label} variant={'h5'}>Descrizione</Text>
              <textarea  {...register('descriptionLegal', descriptionValidator)} placeholder="Descrizione"
                         style={styles.textArea}/>
              {errors?.descriptionLegal && <Text style={styles.error}
                                                 variant={'p1'}>{t(errors.descriptionLegal.message as string) as string}</Text>}
              <Text style={styles.label} variant={'h5'}>N. Domande</Text>
              <Input type={'number'} {...register('maxQuestionLegal', maxQuestionValidator)} placeholder="N. Domande"
                     style={errors?.maxQuestionLegal ? styles.erroredInput : {}}/>
              {errors?.maxQuestionLegal && <Text style={styles.error}
                                                 variant={'p1'}>{t(errors.maxQuestionLegal.message as string) as string}</Text>}
            </div>

            <hr></hr>
            <div style={styles.inputContainer}>
              <Text style={styles.title} variant={'h3'}>Sezione Validation</Text>
              <Text style={styles.label} variant={'h5'}>Descrizione</Text>
              <textarea  {...register('descriptionValidation', descriptionValidator)} placeholder="Descrizione"
                         style={styles.textArea}/>
              {errors?.descriptionValidation && <Text style={styles.error}
                                                      variant={'p1'}>{t(errors.descriptionValidation.message as string) as string}</Text>}
              <Text style={styles.label} variant={'h5'}>N. Domande</Text>
              <Input type={'number'} {...register('maxQuestionValidation', maxQuestionValidator)}
                     placeholder="N. Domande" style={errors?.maxQuestionValidation ? styles.erroredInput : {}}/>
              {errors?.maxQuestionValidation && <Text style={styles.error}
                                                      variant={'p1'}>{t(errors.maxQuestionValidation.message as string) as string}</Text>}
            </div>

            <hr></hr>
            <div style={styles.inputContainer}>
              <Text style={styles.title} variant={'h3'}>Sezione Esg</Text>
              <Text style={styles.label} variant={'h5'}>Descrizione</Text>
              <textarea  {...register('descriptionEsg', descriptionValidator)} placeholder="Descrizione"
                         style={styles.textArea}/>
              {errors?.descriptionEsg && <Text style={styles.error}
                                               variant={'p1'}>{t(errors.descriptionEsg.message as string) as string}</Text>}
              <Text style={styles.label} variant={'h5'}>N. Domande</Text>
              <Input type={'number'} {...register('maxQuestionEsg', maxQuestionValidator)} placeholder="N. Domande"
                     style={errors?.maxQuestionEsg ? styles.erroredInput : {}}/>
              {errors?.maxQuestionEsg && <Text style={styles.error}
                                               variant={'p1'}>{t(errors.maxQuestionEsg.message as string) as string}</Text>}
            </div>

            <div style={styles.buttonContainer}>
              <Button text={t('rooms.common.confirmButton') as string} type={'submit'} disabled={loading}/>
            </div>
          </form>
        </FormProvider>
      </div>
  );
}

const styles = {
  columnHeader: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['spacing-04'],
  },
  newItem: { cursor: 'pointer' },
  select: {
    display: 'flex',
    flex: 1,
    outline: 'none',
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.colors.lightGrey,
    borderRadius: 10,
    paddingLeft: theme.spacing["spacing-05"],
    paddingRight: theme.spacing["spacing-05"],
    paddingTop: theme.spacing["spacing-05"],
    paddingBottom: theme.spacing["spacing-05"],
    ...theme.components.Text.variants.h5,
  },
  label: {
    fontWeight: 'normal',
    marginBottom: theme.spacing['spacing-04'],
    marginTop: theme.spacing['spacing-04']
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing['spacing-06']
  },
  title2: {
    fontWeight: 'bold',
    marginLeft: '12px'
  },
  textArea: {
    ...theme.components.Text.variants.default_text,
    padding: theme.spacing["spacing-05"],
    outline: 'none',
    borderRadius: 10,
    resize: 'none',
    borderColor: theme.colors.lightGrey,
    width:'90%',
  },
  formContainer: {
    display: 'flex',
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    marginTop: theme.spacing['spacing-02'],

  },
  inputContainer: {
    margin: theme.spacing['spacing-04'],
    textAlign: 'left'
  },
  buttonContainer: {
    marginTop: theme.spacing['spacing-08']
  },
  error: {
    color: theme.colors.error
  },
  erroredInput: {
    color: theme.colors.error,
    borderColor: theme.colors.error
  },
  userModal: {
    content: {
      zIndex: 300,
      margin: 'auto',
      width:400,
      height: 600,
      borderRadius: 20,
    },
    overlay: {zIndex: 300, margin: 'auto',width: '100%'}
  }
} as const;

export default ResultForm;
