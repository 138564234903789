import React, {Children} from 'react';

type Props = {
    space?: number,
    children: any,
    style?: React.CSSProperties,
}

const VStack = (props: Props) => {
    const {space, children} = props
    const childrenCount = Children.count(children)

    const isSingleChild = childrenCount === 1

    return (
        <div style={{display: 'flex', flex: 1, flexDirection: 'column', ...props.style}}>
            {isSingleChild ? children : children.map((child: any, i: number) => {
                const isLastElement = (childrenCount - 1) === i
                const spacing = space || 0
                return (
                    <div key={i}
                         style={{flex: 1, display: 'flex', paddingBottom: isLastElement ? 0 : spacing}}>{child}</div>
                )
            })}
        </div>
    );
};

export default VStack;
