
import Missing from "./Missing";
import Unauthorized from "./Unauthorized";
import ResultPage from './ResultPage';
import RouteRoot from './RouteRoot';



export const routes = {
    home: <ResultPage/>,
    missing: <Missing/>,
    unauthorized: <Unauthorized/>,
    routeRoot: <RouteRoot/>,
}

