import React, {useState} from 'react';
import Text from '../components/ui/Text';
import {theme} from '../theme';
import ResultForm from "../components/molecules/forms/ResultForm";
import GetResultResponse from "../models/GetResultResponse";
import Button from "../components/ui/Button/Button";
import {useSocketEmit} from "../hooks/useSocketEmit";
import GetResultRequest from "../models/GetResultRequest";
import SaveResultRequest from "../models/SaveResultRequest";

function ResultPage() {
  const [success] = useState(false);
  const {saveResult} = useSocketEmit()

  const [result, setResult] = useState<GetResultResponse | null>(null);
  const [request, setRequest] = useState<GetResultRequest | null>(null);



  const showResult = (result: GetResultResponse, request: GetResultRequest) => {
    setResult(result)
    setRequest(request)
  }

  const saveResultClick = async () => {
    let saveRequest = new SaveResultRequest(request!, result!)
    console.log(saveRequest)
    let res = await saveResult(new SaveResultRequest(request!, result!))
    if (res) {
      alert("Creato Google Sheet con successo e condivisa via mail")
    }
  }

  const backClick = async () => {
    setResult(null)
    setRequest(null)
  }



  return (
    <div style={styles.root}>
      <h1>SUSA FE</h1>
      {!success && !result &&
        <div style={styles.body}>
          <div style={styles.header}>
            <Text variant={'h3'} style={styles.title} color={theme.colors.darkGrey}>Richiesta AI</Text>
            <Text variant={'h7'} style={styles.subTitle} color={theme.colors.black}>Immetti tutti i dati per creare una richiesta AI</Text>
          </div>
          <ResultForm item={null} callback={showResult}/>
        </div>
      }
      {result &&
        <div style={styles.body}>

          <div style={styles.header}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

              <Text variant={'h3'} style={styles.title} color={theme.colors.darkGrey}>Risultato AI</Text>
              <Button text={"Crea Google Sheet"} onClick={saveResultClick}/>
            </div>

            <Text variant={'h7'} style={styles.subTitle} color={theme.colors.black}>Immetti tutti i dati per creare una richiesta AI</Text>
          </div>

            <Text variant={'h3'} style={styles.title} color={theme.colors.darkGrey}>Domande selezionate</Text>
          {result.selectedQuestionAnswer.map((item, index) => (
            <div>
              <Text variant={'h6'} style={styles.title} color={theme.colors.darkGrey}>{item.question.text + " (" + item.question.topic + ")"}</Text>
              <Text variant={'h7'} style={styles.subTitle} color={theme.colors.black}>{item.answer + ', ' + item.accuracy.toString()}</Text>

            </div>

          ))}

          <div style={{marginBottom: '20px'}}>
            <Button text={"Fai nuova analisi"} onClick={backClick}/>
          </div>


          <Text variant={'h3'} style={styles.title} color={theme.colors.darkGrey}>Domande non selezionate</Text>

          {result.unselectedQuestionAnswer.map((item, index) => (
            <div>
              <Text variant={'h6'} style={styles.title} color={theme.colors.darkGrey}>{item.question.text + " (" + item.question.topic + ")"}</Text>
              <Text variant={'h7'} style={styles.subTitle} color={theme.colors.black}>{item.answer + ', ' + item.accuracy.toString()}</Text>

            </div>

          ))}




        </div>
      }
    </div>
)};

const styles = {
  root: {
    textAlign: 'center',
    display: 'flex',
    height:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'column',
  },

  logo: {
    width: 200,
  },

  body:{
    textAlign: 'left',
    display: 'flex',
    alignItems: 'left',
    flexDirection:'column',
    backgroundColor: theme.colors.lighterGrey,
    padding: theme.spacing['spacing-05'],
    borderRadius:10,
  },

  header: {
    justifyItems: 'left'
  },
  title: {marginBottom:theme.spacing['spacing-05'], textAlign:'justify', textJustify: 'initial'},
  subTitle: { marginBottom:theme.spacing['spacing-07'], textAlign:'justify', textJustify: 'auto'},
  terms: {
    paddingTop: theme.spacing['spacing-05'],
    paddingBottom: theme.spacing['spacing-05'],
    width:'100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  buttonContainer: {
    marginTop: theme.spacing['spacing-05'],
  },
  inputContainer:{
    position:'relative',
    marginBottom: theme.spacing['spacing-05']
  },
  registrationOkContainer:{
    height:455,
    display:'flex',
    alignItems:'center',
    flexDirection:'column',
    justifyContent:'space-between',
    padding: theme.spacing['spacing-05']
  },
  registrationOkContainerBody:{
    marginTop:100,
  },
  registrationOk:{
    height:80,
    width:80,
    marginBottom:theme.spacing['spacing-05']
  },
  storeButtonsContainer: {
    position:'relative',
    bottom:0,
    display:'flex',
    flexDirection: 'row'
  },
  storeButton: {
    width:150,
  },
  eye: {
    position:'absolute',
    right:10,
    top:20,
    zIndex:100,
    width:24,
    height:24,
  },
  error: {
    marginBottom: theme.spacing['spacing-03'],
    flexDiretion: 'row',
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  actionButton:{
    width: 311,
    height: 46,
  }

} as const


export default ResultPage;
