import {createContext, useContext, useState} from "react";
import {ReactElementPropsType} from '../types';


interface IModalContext {
    modal?:any,
    setModal?:any,
    closeModal?: any,
    openModal?: any,
    resetModal?: any,
}

const initialContext: IModalContext = {}

export const ModalContext = createContext(initialContext);

export const useModal = () => useContext(ModalContext)

type ModalDataType = {
    title: string,
    child: any,
    closeCallback?: ()=>{}
} | null

type ModalType = {
    open: boolean,
    data?: ModalDataType,
    history: object[]
}


export const ModalProvider = (props: ReactElementPropsType) => {
    const [modal, setModal] = useState<ModalType>({
        open: false,
        history: []
    });


    const openModal = (data: ModalDataType) => {
        setModal((modal: any) => {
                return {
                    open: true,
                    data: data,
                    history: modal.history.length > 0 ? [...modal.history, modal.data] : modal.data ? [modal.data] : [],
                }
            }
        )
    }

    const closeModal = () => {
        modal.data?.closeCallback && modal.data?.closeCallback()
        const hasHistory = modal?.history?.length > 0
        if (hasHistory) {
            setModal((modal: any) => {
                const historyLength = hasHistory && modal.history?.length
                const newData = modal.history[historyLength - 1]
                let newHistory = null
                if (hasHistory) {
                    newHistory = modal.history.slice(0, historyLength - 1)
                }

                return ({
                    open: true,
                    data: newData,
                    history: newHistory
                })
            })
        } else {
            setModal({open: false, data: null, history: []})
        }
    }

    const resetModal = () => {
        setModal({open: false, data: null, history: []})
    }

    return (
        <ModalContext.Provider value={{modal, setModal, closeModal, openModal, resetModal}}>
            {props && props.children}
        </ModalContext.Provider>
    )
};
