import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import * as resources from './resources/index'
import LanguageDetector from "i18next-browser-languagedetector";

export const availableLanguages = Object.keys(resources)

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        defaultNS: "common",
        fallbackLng: "it",
    }).catch(e=>{console.error(e)});
