import {createContext, useEffect, useRef, useState} from "react";
import socketIOClient, {Socket} from 'socket.io-client';
import {useDispatch} from "react-redux";
import moment from "moment";
import {config} from "../config";

interface X {
    socket: Socket|null,
    socketReady: boolean,
}

const initialSocket : X = {
    socket: null,
    socketReady: false,
}
export const SocketContext = createContext(initialSocket);


const SocketProvider = (props: any) => {
    const dispatch = useDispatch()
    const [socketReady, setSocketReady] = useState(false)

    console.log("try to connect");
    const client = socketIOClient("https://susa-poc-be.longwavestudio.dev/susa", {
        transports: ['websocket'],
        query: {
            version: 1, // optional chat major version supported by clients;
            platform: 'web', // optional string defining client platform;
            appVersion: 1, // optional client app major version;
            lang: 'it' // optional default on server IT
        }
    })

    const socket = useRef(client)

    useEffect(() => {
        socket.current.on(config.events.CONNECTED, (res) => {
            console.log('[connected]', res)
            setSocketReady(true)
            console.log(moment().format('MMMM Do YYYY, h:mm:ss a'), 'SocketIO: Connected and authenticated');
        });

        socket.current.on(config.events.CLOSED, () => {
            console.log('disconnected')
            setSocketReady(false)
        })

        socket.current.on(config.events.ERROR, (msg) => {
            console.error('SocketIO: Error', msg);
        });

        socket.current.on(config.events.CONNECT_ERROR, (msg) => {
            console.error('SocketIO: Connect Error', msg);
        });
    }, [dispatch]);

    return (
        <SocketContext.Provider
            value={{socket: socket.current, socketReady: socketReady}}>{props.children}</SocketContext.Provider>
    );
};

export default SocketProvider;
