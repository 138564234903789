import React from 'react';
import './App.css';
import {config} from './config';
import {Route, Routes} from 'react-router-dom';
import {routes} from "./routes";
import IgnoreRoute from "./components/IgnoreRoute";
import Layout from "./components/layout/Layout";
import RequireAuth from "./components/RequireAuth";
import './translations/i18n'

function App() {

    return (
      <Routes>
          <Route path="/" element={<Layout/>}>
              {/*public routes*/}
              {/*protected routes*/}
              <Route element={<RequireAuth allowedRoles={[config.loginRoles.ADMIN]}/>}>
                <Route path={config.routes.HOME} element={routes.home}/>
              </Route>
              <Route path={config.routes.UNAUTHORIZED} element={routes.unauthorized}/>
              {/*catch all routes*/}
              <Route path={''} element={routes.routeRoot} />
              <Route path={config.routes.MISSING} element={routes.routeRoot} />

          </Route>


      </Routes>
  );
}

export default App;
