import React, {ReactElement} from 'react';
import {theme} from "../../theme";

type Props = {
    style?: React.CSSProperties,
    onClick?: (a: any) => void,
    children: string | ReactElement,
    color?: string,
    variant: string,
}
function Text(props: Props) {
    const {color, variant} = props
    return (
        <div
            style={{color: color || theme.colors.darkGrey, ...theme.components.Text.variants[variant], ...props.style}}
            onClick={props.onClick}>
            {props.children}
        </div>
    );
}

export default Text;
