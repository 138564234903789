export const it = {
  common: {
    components: {
      navbar:{
        title:'Susa FE',
        rooms: 'Stanze',
        programs: 'Programmi',
        users: 'Utenti',
      },
      closeLabel: 'Chiudi',
    },
    routes: {
      login: {
        form: {
          title: 'Accedi',
          placeholders: {
            email: 'Email',
            password: 'Password',
            recover: 'Recupera email',
            back: 'Torna al login',
          },
          button: 'Continua',
          forgotPassword: 'Password dimenticata?',
        },
      },
      profile: {
        logout: 'Logout',
        back: 'Indietro',
      },
      register: {
        title: 'Crea una password',
        subTitle: 'Ciao, per completare la registrazione è necessario creare una password. Utilizza almeno una lettera maiuscola, una lettera minuscola e un numero',
        passwordPlaceholder: 'Password',
        confirmPasswordPlaceholder: 'Conferma password',
        termsLabel: 'Accetta',
        termsLink: 'Termini e Condizioni',
        completeRegistration: 'Completa registrazione',
        termsTitle: 'Termini e condizioni',
        errors: {
          passwordMismatch: 'le password devono coincidere',
          passwordTooShort: 'lunghezza minima 8 caratteri',
          wrongFormat: 'la password deve contenere un carattere minuscolo, uno maiuscolo ed almeno un numero',
          passwordEmpty: 'scegli la password',
          confirmPasswordEmpty: 'conferma la password',
          exception: 'Qualcosa è andato storto, riprova più tardi',
          passwordRecoverLink: ' recupera password'
        },
        registrationSuccessful: 'Registrazione avvenuta con successo',
        changePasswordTitle: 'Crea nuova password',
        changePasswordSubTitle: 'Ciao, stai per creare una nuova password. Utilizza almeno una lettera maiuscola, una lettera minuscola e un numero',
        setNewPassword: 'Imposta nuova password',
        setPasswordSuccessful: 'Password aggiornata con successo',
        termsContent: 'TO BE CHANGED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!',
      },
    },
    rooms: {
      title:'Stanze',
      addButton: 'Aggiungi',
      editButton: 'Modifica',
      common:{
        name: 'Nome',
        description: 'Descrizione',
        vmRef: 'Macchina virtuale',
        program: 'Programma',
        confirmButton: 'OK',
        editButton: 'Modifica',
        deleteButton: 'Rimuovi',
        deleteMessage: 'Sei sicuro di voler procedere?'
      },
      addModal: {
        title: 'Nuova Stanza',
        usersButton: 'Utenti',
        enableUserModal: {
          title: 'Abilita utenti',
          chooseAtListOneUser: 'Scegli almeno un utente da abilitare',
          code400: 'I valori non sono corretti: {{message}}',
          code500: 'Qualcosa è andtato storto, riprova: {{message}}',
        }
      },
      editModal: {
        title: 'Modifica Stanza',
      },
      errors: {
        fillTheField: 'Compila il campo',
        programIdNotValid: 'Programma non valido',
        minLength: "Troppo corto",
        maxLength: "Troppo lungo",
        code400: 'I valori non sono corretti: {{message}}',
        code500: 'Qualcosa è andtato storto, riprova: {{message}}',
      }
    },
    programs: {
      title:'Programmi',
      addButton: 'Aggiungi',
      editButton: 'Modifica',
      common:{
        name: 'Nome',
        description: 'Descrizione',
        confirmButton: 'OK',
        editButton: 'Modifica',
        deleteButton: 'Rimuovi',
        imageUrl: 'Url immagine',
        deleteMessage: 'Sei sicuro di voler procedere? Tutte le stanze di questo programma verranno rimosse'
      },
      addModal: {
        title: 'Nuovo Programma',
      },
      editModal: {
        title: 'Modifica Programma',
      },
      errors: {
        fillTheField: 'Compila il campo',
        minLength: "Troppo corto",
        maxLength: "Troppo lungo",
        code400: 'I valori non sono corretti: {{message}}',
        code500: 'Qualcosa è andtato storto, riprova: {{message}}',
        invalidImageUrl: 'Url non valido',

      }
    },
    users: {
      title:'Utenti',
      addButton: 'Aggiungi',
      resendInviteButton: 'Invita di nuovo',
      resendSuccess:'Invito spedito con successo',
      deleteSuccess:'Utente rimosso con successo',
      askToDelete:'Sei sicuro di voler rimuovere l\'utente?',
      deleteUserButton: 'Rimuovi',
      editUserButton: 'Modifica',
      common:{
        name: 'Nome',
        email: 'Email',
        role: 'Ruolo',
        status: 'Stato',
        confirmButton: 'OK',
        sipUsername: 'Sip username',
        sipPassword: 'Sip password',
      },
      addModal: {
        title: 'Nuovo Utente',
      },
      editModal: {
        title: 'Modifica Utente',
      },
      errors: {
        fillTheField: 'Compila il campo',
        minLength: "Troppo corto",
        maxLength: "Troppo lungo",
        code400: 'I valori non sono corretti: {{message}}',
        code500: 'Qualcosa è andtato storto, riprova: {{message}}',
        invalidEmail: 'Email non valida',
        generic: 'Qualcosa è andtato storto, riprova: {{message}}',
      }
    },
    table: {
      noDataComponentText: 'Non sono presenti dati',
    }
  }
};
