import styled from "styled-components";
import {ButtonProps} from "./types";
import {theme} from "../../../theme";


export const StyledButton = styled.button<ButtonProps>`
  border-radius: 55px;
  color: ${theme.colors.white};
  padding-left: ${theme.spacing["spacing-04"]}px;
  padding-right: ${theme.spacing["spacing-04"]}px;
  ${theme.components.Text.variants.h5};
  min-width: 100px;
  align-items: center;
  justify-content: center;
  border-color: transparent;
  cursor: pointer;
  //opacity: (clicked || disabled) ? 0.5 : 1,
  background-color: ${props => props.background ? props.background : theme.colors.primary};
  ${props => ({
    // backgroundColor: props.background ? props.background : theme.colors.primary
    opacity: (props.clicked || props.disabled) ? 0.5 : 1,
    height: props.height || 38 
  })};
`;
