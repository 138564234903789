import {Outlet} from "react-router-dom";
import SocketProvider, {SocketContext} from "../contexts/SocketProvider";
import {useContext} from "react";
import {config} from "../config";
import Loading from "../routes/Loading";
import ProvidersWrapper from "../ProvidersWrapper";
import {ResultProvider} from "../contexts/ResultProvider";
import {ModalProvider} from '../contexts/ModalContext';


type Props = {
    allowedRoles: [typeof config.loginRoles[keyof typeof config.loginRoles]]
}
const RequireAuth = (props: Props) => {
    const {socketReady} = useContext(SocketContext)
    return (
      <SocketProvider>
          {
              socketReady ? <Loading/>
                : <ProvidersWrapper
                  providers={[
                      // @ts-ignore
                      <ModalProvider />,
                      <ResultProvider />,
                  ]}>
                    <Outlet/>
                </ProvidersWrapper>
          }
      </SocketProvider>
    );
}

export default RequireAuth;
