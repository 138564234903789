const config = {
  links:{
    TERMS_LINK: '/Terms.html',
    UPDATE_PASSWORD_URL: process.env.REACT_APP_API_URL + 'user/updatePasswordAuthenticated',
    RESET_PASSWORD_URL: process.env.REACT_APP_API_URL + 'user/%userId%/resetPassword',
  },
  routes: {
    ROOT: '/',
    // HOME: '/bo/dashboard',
    // LOGIN: '/bo/login',
    UNAUTHORIZED: '/bo/unauthorized',
    BO_MISSING: '/bo/*',
    USER_MISSING: '/u/*',
    MISSING: '*',
    HOME: 'home',
    // REGISTER_USER_LANDING: '/u/register',
  },
  loginRoles: {
    ADMIN: 'admin'
  },
  endpoints: {
    LOGIN: 'user/authenticate',
    REFRESH: 'user/token/refresh',
    SEND_RECOVERY_LINK: 'user/recover',
  },
  events: {
    CONNECTED: 'connected',
    CONNECT_ERROR: 'connect_error',
    CLOSED: 'closed',
    ERROR: 'error',
  },
  actions: {
    GET_RESULT: 'get-result',
    SAVE_RESULT: 'save-result',
  },
  roles: {
    USER: 'user',
    EDITOR: 'editor',
    ADMIN: 'admin',
  },
  userStatus: {
    PENDING: 'pending',
    ACTIVE: 'active',
  },
}

export { config };
