import GetResultResponse from "./GetResultResponse";
import GetResultRequest from "./GetResultRequest";

export default class SaveResultRequest {
  resultRequest: GetResultRequest;
  resultResponse: GetResultResponse;

  constructor(resultRequest: GetResultRequest, resultResponse: GetResultResponse) {
    this.resultRequest = resultRequest;
    this.resultResponse = resultResponse;
  }
}

