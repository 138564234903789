import TopicConfigRequest from "./TopicConfigRequest";
import {AIConfig} from "./AIConfig";

export default class GetResultRequest {
  accuracyThreshold: number;
  topicConfigs: TopicConfigRequest[];
  aiConfig: AIConfig;

  constructor(accuracyThreshold: number, topicConfigs: TopicConfigRequest[], aiConfig: AIConfig) {
    this.accuracyThreshold = accuracyThreshold;
    this.topicConfigs = topicConfigs;
    this.aiConfig = aiConfig;
  }
}

