import React from 'react'

type Props = {
    providers: any,
    children: any,
}
const ProvidersWrapper = (props: Props) => {
    const renderProvider: (providers: any, children: any) => (React.FunctionComponentElement<null>) = (providers: typeof props.providers, children: typeof props.children) => {
        const [provider, ...restProviders] = providers;

        if (provider) {
            return React.cloneElement(
                provider,
                // @ts-ignore
                null,
                renderProvider(restProviders, children)
            )
        }

        return children;
    }

    return renderProvider(props.providers, props.children)
}

export default ProvidersWrapper
