import {useEffect} from 'react';
import {config} from '../config';
import {useNavigate} from 'react-router-dom';


const RouteRoot = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(config.routes.HOME, {replace: true})

    }, []);
    return <></>;
}

export default RouteRoot
