import {createContext, useContext, useEffect, useState} from "react";
import {config} from "../config";

export const FormContext = createContext([]);

export const FormProvider = (props: any) => {
    const [formData, setFormData] = useState()

    return (
        // @ts-ignore
        <FormContext.Provider value={{formData, setFormData}}>
            {props.children}
        </FormContext.Provider>
    )
};


