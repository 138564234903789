export const theme : {[index: string]:any} = {
    components: {
        Text: {
            variants: {
                default_text: {
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: '24px',
                    fontFamily: 'Titillium Web, sans-serif'
                },
                h0: {
                    fontWeight: 700,
                    fontSize: 40,
                    lineHeight: '40px',
                    fontFamily: 'Titillium Web, sans-serif'
                },
                h1: {
                    fontWeight: 700,
                    fontSize: 32,
                    lineHeight: '32px',
                    fontFamily: 'Titillium Web, sans-serif'
                },
                h2: {
                    fontWeight: 300,
                    fontSize: 26,
                    lineHeight: '30px',
                    fontFamily: 'Titillium Web, sans-serif'
                },
                h3: {
                    fontWeight: 700,
                    fontSize: 22,
                    lineHeight: '22px',
                    fontFamily: 'Titillium Web, sans-serif'
                },
                h4: {
                    fontWeight: 400,
                    fontSize: 20,
                    lineHeight: '20px',
                    fontFamily: 'Titillium Web, sans-serif'
                },
                h5: {
                    fontWeight: 600,
                    fontSize: 18,
                    lineHeight: '18px',
                    fontFamily: 'Titillium Web, sans-serif'
                },
                h6: {
                    fontWeight: 700,
                    fontSize: 15,
                    lineHeight: '15px',
                    fontFamily: 'Titillium Web, sans-serif'
                },
                p1: {
                    fontWeight: 400,
                    fontSize: 13,
                    lineHeight: '13px',
                    fontFamily: 'Titillium Web, sans-serif'
                },
                p2: {
                    fontWeight: 400,
                    fontSize: 11,
                    lineHeight: '20px',
                    fontFamily: 'Titillium Web, sans-serif'
                }
            }
        }
    },
    colors: {
        primary: '#50AFF4',
        white: '#FFFFFF',
        lighterGrey: '#F9F9F9',
        lightGrey: '#D1D1D6',
        midGrey: '#8E8E93',
        darkGrey: '#444446',
        black: '#000000',
        success: '#198038',
        warning: '#F1C21B',
        error: '#DA1E28',
    },
    spacing: {
        "spacing-01": 2,
        "spacing-02": 4,
        "spacing-03": 8,
        "spacing-04": 12,
        "spacing-05": 16,
        "spacing-06": 24,
        "spacing-07": 32,
        "spacing-08": 40,
        "spacing-09": 48,
        "spacing-10": 64,
        "spacing-11": 80,
        "spacing-12": 96,
        "spacing-13": 160,
    }
}
