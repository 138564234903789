export const aiParamValidator = {
  required: 'rooms.errors.fillTheField',
  minLength: { value: 1, message: 'rooms.errors.minLength' },
  maxLength: { value: 4, message: 'rooms.errors.maxLength' },
};

export const modelValidator = {
  required: 'rooms.errors.fillTheField',
};
export const descriptionValidator = {
  minLength: { value: 0, message: 'rooms.errors.minLength' },
  maxLength: { value: 17000, message: 'rooms.errors.maxLength' },
};

export const maxQuestionValidator = {
  required: 'rooms.errors.fillTheField',
  minLength: { value: 1, message: 'rooms.errors.minLength' },
  maxLength: { value: 2, message: 'rooms.errors.maxLength' },
};
